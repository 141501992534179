
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  distanceX: number = 0
  isMove: boolean = false
  domWidth: number = 0
  activeIndex: number = 0
  @Prop({ default: 0 }) activeTab!: number
  @Prop({ default: 0 }) maxLength!: number
  @Prop({ default: '' }) className!: string
  @Prop({ default: () => [] }) slideList!: Array<any>
  @Prop({ default: 1 }) type!: number
  @Watch('activeTab') handleTabChange(val: number) {
    this.activeIndex = val
  }
  @Watch('activeIndex') handleIndexChange(val: number) {
    let domClient = document.body || document.documentElement
    let clientX = domClient['offsetWidth']
    let dom = document.querySelectorAll(
      `.${this.className} .slide-container`
    )[0]
    if (this.slideList.length && this.className) {
      this.domWidth = document.querySelectorAll(
        `.${this.className} .slide-item`
      )[0]['offsetWidth']
    }
    let radio = this.$store.state.baseFontSize
    if (this.maxLength) {
      dom['style'].transform = `translateX(-${(clientX * val) / radio}rem)`
    } else {
      if (val == 0) {
        dom['style'].transform = `translateX(0rem)`
      } else if (val == this.slideList.length - 1) {
        let dis = this.type == 2 ? 0.4 : 0
        dom['style'].transform = `translateX(${
          (clientX - dom['offsetWidth']) / radio - dis
        }rem)`
      } else {
        let diff = (clientX - this.domWidth) / 2
        dom['style'].transform = `translateX(${
          (-this.domWidth * val + diff) / radio - 0.2
        }rem)`
      }
    }
  }
  mounted() {
    if (this.className) {
      if (this.slideList.length) {
        this.domWidth = document.querySelectorAll(
          `.${this.className} .slide-item`
        )[0]['offsetWidth']
      }
      let dom = document.querySelectorAll(
        `.${this.className} .slide-container`
      )[0]
      let startX = 0
      dom.addEventListener('touchstart', (e: any) => {
        e.stopPropagation()
        startX = e.touches[0].pageX
      })
      dom.addEventListener('touchmove', (e: any) => {
        e.stopPropagation()
        this.isMove = true
        this.distanceX = e.touches[0].pageX - startX
      })
      dom.addEventListener('touchend', (e: any) => {
        e.stopPropagation()
        if (this.isMove && Math.abs(this.distanceX) >= 30) {
          if (this.distanceX > 0) {
            // 右滑
            this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : 0
          } else {
            // 左滑
            let limit = this.slideList.length
              ? this.slideList.length
              : this.maxLength
            this.activeIndex =
              this.activeIndex < limit - 1 ? this.activeIndex + 1 : limit - 1
          }
          this.isMove = false
          this.distanceX = 0
          this.$emit('handleItemSlide', this.activeIndex)
        }
      })
    }
  }
  handleTap(e: any, i: number) {
    this.$emit('handleItemTap', { e, i })
  }
}
