import { render, staticRenderFns } from "./Slide.vue?vue&type=template&id=47868ba2&scoped=true&"
import script from "./Slide.vue?vue&type=script&lang=ts&"
export * from "./Slide.vue?vue&type=script&lang=ts&"
import style0 from "./Slide.vue?vue&type=style&index=0&id=47868ba2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47868ba2",
  null
  
)

export default component.exports