
import { Component, Prop, Vue } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  @Prop({ default: 1 }) type!: number
  @Prop() textBg!: string
  @Prop() textTopic!: string
  imgBgList: Array<any> = [
    require('@/assets/images/common/title-bg-green.png'),
    require('@/assets/images/common/title-bg-purple.png'),
  ]
}
